export var TagType;
(function (TagType) {
    TagType["Org"] = "ORG";
    TagType["Group"] = "GROUP";
    TagType["Label"] = "LABEL";
})(TagType || (TagType = {}));
export var OperateType;
(function (OperateType) {
    OperateType[OperateType["Add"] = 0] = "Add";
    OperateType[OperateType["Edit"] = 1] = "Edit";
    OperateType[OperateType["Del"] = 2] = "Del";
})(OperateType || (OperateType = {}));
export var SourceType;
(function (SourceType) {
    SourceType["SelfMaintain"] = "SELF_MAINTENANCE";
    SourceType["DingtalkMaintain"] = "DINGTALK";
    SourceType["WechatMaintain"] = "WECHAT";
})(SourceType || (SourceType = {}));
export var ShareType;
(function (ShareType) {
    ShareType["Sys"] = "SYS";
    ShareType["Org"] = "ORG";
})(ShareType || (ShareType = {}));
export var UnitType;
(function (UnitType) {
    UnitType[UnitType["Dept"] = 1] = "Dept";
    UnitType[UnitType["Role"] = 2] = "Role";
    UnitType[UnitType["User"] = 3] = "User";
})(UnitType || (UnitType = {}));
